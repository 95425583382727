import React from 'react'
import './ConstructionPage.scss'
import SEO from '../../components/seo/Seo'

export default function ConstructionPage() {
  return (
    <div className='ConstructionPage'>
      <SEO id='construction' />
      <div className='container'>
        <div className='size-54 embed-title text-center bold'>
          This is not the page you're looking for...
        </div>
        <div className='size-14 semi-bold text-center sub-title'>
          Or maybe a pesky kid with a lightsaber took us down
        </div>
      </div>
    </div>
  )
}
